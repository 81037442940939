import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import Moment from 'moment';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import GenericModal from './generic_modal';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, PERIOD_BUDGET_TYPE, PERIODICITY} from '../../constants';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import {Dropdown} from 'web-components';
import DatePickerV3 from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import RadioButton from '../../components/new-ui/radio_button';
import ColorsPicker from '../../components/colors_picker';
import Util from '../../forecast-app/shared/util/util';
import SyncJiraIntegrationMutation from '../../mutations/sync_jira_integration_mutation';
import jiraUtil from '../../components/jira/jira_util';
import InputFieldV2 from '../../components/new-ui/input_field';
import NewProjectModalDetailedSettings from '../../components/new-ui/new_project_modal_detailed_settings';
import projectUtil from '../../forecast-app/shared/util/project_util';
import {CSS_CONSTANTS} from '../../css_variables';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import DirectApi, {projectUrl} from '../../directApi';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import CreateRetainerPeriodMutation from '../../mutations/create_retainer_period_mutation';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import InformationIcon from '../../images/information-icon';

class syncJiraProjectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			detailedSettingsExpanded: false,
			selectedJiraProject: props.syncType === 'project' && props.jiraProject ? props.jiraProject.id : null,
			selectedJiraProjectStyle: props.syncType === 'project' && props.jiraProject ? props.jiraProject.style : null,
			selectedJiraEpics: (props.syncType === 'epics' && props.jiraEpics) || [],
			jiraEpics: null,
			forecastProjectName:
				props.jiraProject && props.jiraProject.name
					? props.jiraProject.name
					: props.forecastProject
					? props.forecastProject.name
					: '',
			syncTeammembersCount: null,
			syncMilestonesCount: null,
			syncSprintsCount: null,
			syncEpicEpicCount: null,
			syncTasksCount: null,
			syncSubtasksCount: null,
			selectedClient: null,
			selectedJiraBoard: null,
			boardsDeepFetched: false,
			synchronizeSprints: false,
			useTimeOriginalEstimate: !props.jiraProject || props.jiraProject.style !== 'next-gen',
			projectEndDate: null,
			projectColor: '#bc97f5',
			storyPointsOrCustomField: null,
			jira_board_options: [],
			custom_field_options: [],
			useSprints: false,
			errorMessag: '',
			sprintLengthInDays: 14,
			useManualAllocations: false,
			budgetType: BUDGET_TYPE.TIME_AND_MATERIALS,
			invalidFixedPriceFormat: false,
			rateCardSelected: this.getDefaultRateCard(),
			fixedPriceBudget: null,
			defaultPeriodPeriodicity: PERIODICITY.MONTHLY,
			wrongPeriodLength: false,
			defaultPeriodLength: 1,
			defaultPeriodBudgetType: PERIOD_BUDGET_TYPE.FIXED_HOURS,
			defaultPeriodHoursAmount: null,
			defaultPeriodPriceAmount: null,
			projectStartDate: Moment(),
			preCreatePeriods: false,
			preCreateAmount: null,
			periodStartDate: Moment(),
			periodStartDateHovered: null,
			projectLabels: [],
			subTaskType: 'cards',
			epicType: props.syncType === 'project' ? 'task' : 'milestone',
			jiraTypeToPhase: props.syncType === 'project' ? 'versions' : 'epics',
			synching: false,
			unsynchedEpicWorklogs: 0,
			syncRemaining: false,
			useCutoffDate: false,
			cutoffDate: null,
		};
	}

	async componentDidMount() {
		if (this.props.syncType === 'project' && this.state.selectedJiraProject !== null) {
			this.fetchJiraBoards(false);
			this.getJiraProjectIssuesCount(this.state.selectedJiraProject);
			this.getJiraProjectTeamMembersCount(this.state.selectedJiraProject);
			this.refetchPhaseCounts();
		} else if (this.props.syncType === 'epics' && this.state.selectedJiraEpics?.length) {
			this.fetchJiraBoards(true);
			this.getJiraEpicsIssuesCount(this.state.selectedJiraEpics);
			this.setState({syncMilestonesCount: this.state.selectedJiraEpics.length});
			this.fetchWorklogsOnEpics(this.state.selectedJiraEpics);
		}

		if (this.props.syncType === 'epics') this.fetchEpics();

		this.fetchJiraCustomFields();
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedJiraProject !== this.state.selectedJiraProject) {
			this.fetchJiraBoards(false);
			this.getJiraProjectTeamMembersCount(this.state.selectedJiraProject);
			this.fetchJiraCustomFields();
		}
		if (prevState.selectedJiraEpics?.length !== this.state.selectedJiraEpics?.length) {
			this.getJiraEpicsIssuesCount(this.state.selectedJiraEpics);
			this.setState({syncMilestonesCount: this.state.selectedJiraEpics.length});
			this.fetchWorklogsOnEpics(this.state.selectedJiraEpics);
		}
		if (
			prevState.selectedJiraProject !== this.state.selectedJiraProject ||
			prevState.selectedJiraEpics?.length !== this.state.selectedJiraEpics?.length ||
			prevState.cutoffDate !== this.state.cutoffDate ||
			prevState.epicType !== this.state.epicType
		) {
			if (this.state.selectedJiraProject) {
				this.refetchPhaseCounts();
				this.getJiraProjectIssuesCount(this.state.selectedJiraProject);
			} else if (this.state.selectedJiraEpics) {
				this.getJiraEpicsIssuesCount(this.state.selectedJiraEpics);
			}
		}

		if (
			this.state.selectedJiraBoard &&
			(prevState.selectedJiraBoard !== this.state.selectedJiraBoard || prevState.cutoffDate !== this.state.cutoffDate)
		) {
			this.getJiraSprintsCount(this.state.selectedJiraBoard);
		}
	}

	refetchPhaseCounts() {
		if (this.state.epicType === 'milestone') {
			this.fetchEpicsForProject(this.state.selectedJiraProject);
		} else {
			this.getJiraVersionsCount(this.state.selectedJiraProject);
		}
	}

	async fetchJiraBoards(deep) {
		const response = await jiraUtil.fetchJiraBoards(this.state.selectedJiraProject, deep, this.props.jiraType);
		if (response) {
			const jira_board_options =
				response.boards && response.boards.length > 0
					? response.boards.map(board => ({value: board.id, label: board.name}))
					: [];
			const selectedJiraBoard = jira_board_options.length !== 0 ? jira_board_options[0].value : null;
			this.setState({jira_board_options, selectedJiraBoard, boardsDeepFetched: response.deepFetch});
		}
	}

	async fetchJiraCustomFields() {
		const response = await jiraUtil.fetchJiraCustomFields(
			this.props.jiraType,
			this.state.selectedJiraProjectStyle || 'any'
		);
		const options = response.map(el => ({value: el.id, label: el.name}));
		this.setState({
			custom_field_options: options,
			storyPointsOrCustomField: options.length > 0 ? options[0].value : undefined,
		});
	}

	//region Fetch jira project counters
	async getJiraProjectTeamMembersCount(jiraProjectId) {
		const response = await jiraUtil.fetchUsersCountForJiraProject(jiraProjectId, this.props.jiraType);
		this.setState({syncTeammembersCount: response.count ? response.count : 0});
	}

	async getJiraProjectIssuesCount(jiraProjectId) {
		const response = await jiraUtil.fetchIssuesCountForJiraProject(
			jiraProjectId,
			this.props.jiraType,
			this.state.cutoffDate
		);
		this.setState({
			syncTasksCount: response.tasks
				? response.tasks - (this.state.epicType === 'milestone' ? this.state.syncMilestonesCount : 0)
				: 0,
			syncSubtasksCount: response.subtasks ? response.subtasks : 0,
		});
	}

	async getJiraProjectEpicsCount(jiraProjectId) {
		const response = await jiraUtil.fetchEpicsCountForJiraProject(
			jiraProjectId,
			this.props.jiraType,
			this.state.cutoffDate
		);
		this.setState({
			syncEpicEpicCount: response.tasks ? response.tasks : 0,
		});
	}

	async getJiraEpicsIssuesCount(jiraEpicIds) {
		const response = await jiraUtil.fetchIssuesCountForJiraEpics(jiraEpicIds, this.props.jiraType, this.state.cutoffDate);
		this.setState({
			syncTasksCount: response.tasks ? response.tasks : 0,
			syncSubtasksCount: response.subtasks ? response.subtasks : 0,
		});
	}

	async getJiraSprintsCount(boardId) {
		const response = await jiraUtil.fetchSprintsCountForJiraBoard(boardId, this.props.jiraType, this.state.cutoffDate);
		this.setState({syncSprintsCount: response.count ? response.count : 0});
	}

	async getJiraVersionsCount(jiraProjectId) {
		const response = await jiraUtil.fetchVersionsCountForJiraProject(
			jiraProjectId,
			this.props.jiraType,
			this.state.cutoffDate
		);
		this.setState({syncMilestonesCount: response.count ? response.count : 0});
	}

	//endregion

	//region START Detailed settings functions
	togggledetailedSettings() {
		this.setState({detailedSettingsExpanded: !this.state.detailedSettingsExpanded});
	}

	getDefaultRateCard() {
		return projectUtil.getDefaultRateCard(
			this.props.intl.formatMessage,
			this.props.viewer.company.defaultRateCard,
			this.props.viewer.company.currency
		);
	}

	handleSprintLengthChange(value) {
		this.setState({sprintLengthInDays: projectUtil.handleSprintLengthChange(value)});
	}

	handleDetailedSettingsCheckboxChange(value, field) {
		this.setState({[field]: value});
	}

	onProjectTypeChange(useManualAllocations) {
		if (useManualAllocations !== this.state.useManualAllocations) {
			this.setState({useManualAllocations: useManualAllocations});
		}
	}

	onBudgetTypeChange(newValues) {
		this.setState(newValues);
	}

	handleFixedPriceBudget(fixedPriceBudget) {
		this.setState({fixedPriceBudget, invalidFixedPriceFormat: !parseFloat(fixedPriceBudget)});
	}

	handleSelectRateChange(rate) {
		this.setState({
			rateCardSelected: rate,
		});
	}

	onPeriodicityChange(periodicity) {
		if (periodicity !== this.state.defaultPeriodPeriodicity) {
			this.setState({defaultPeriodPeriodicity: periodicity.value});
		}
	}

	onPeriodBudgetTypeChange(periodBudgetType) {
		if (periodBudgetType !== this.state.defaultPeriodBudgetType) {
			this.setState({defaultPeriodBudgetType: periodBudgetType[0]});
		}
	}

	handlePeriodLengthChange(value) {
		this.setState({
			defaultPeriodLength: value.defaultPeriodLength,
			wrongPeriodLength: value.wrongPeriodLength,
			errorMessage: value.errorMessage,
		});
	}

	handledefaultPeriodChange(field, value) {
		this.setState({[field]: value < 0 ? 0 : parseFloat(value)});
	}

	handleNumberPeriodChange(value) {
		this.setState({preCreateAmount: Math.round(value)});
	}

	onNumberPeriodChangeBlur() {
		if (this.state.preCreateAmount < 1) this.setState({preCreateAmount: 1});
	}

	updateLabels(labels) {
		this.setState({projectLabels: labels});
	}

	fetchEpics() {
		DirectApi.Fetch(`jira/available_epics`).then(json =>
			this.setState({
				jiraEpics: json.map(epic => ({
					id: epic.id,
					key: epic.key,
					summary: epic.fields.summary,
				})),
			})
		);
	}

	fetchEpicsForProject(projectId) {
		DirectApi.Fetch(
			`jira/${this.props.jiraType}/epics/${projectId}${
				this.state.cutoffDate ? `?cutoffDate=${this.state.cutoffDate.format('YYYY-MM-DD')}` : ''
			}`
		).then(data => {
			if (data) {
				this.setState({syncMilestonesCount: data.length});
				if (data.length > 0) {
					this.fetchWorklogsOnEpics(data.map(epic => epic.id));
				}
			}
		});
	}

	fetchWorklogsOnEpics(epicIds) {
		// Only works for cloud for now
		if (this.props.jiraType === 'cloud') {
			DirectApi.Fetch(`jira/worklogs_on_unsynced_epics?issueIds=${epicIds}`).then(data => {
				if (data) {
					this.setState({
						unsynchedEpicWorklogs: data.numberOfWorklogs || 0,
					});
				}
			});
		}
	}

	//endregion

	syncProject() {
		const onSuccess = result => {
			const {formatMessage} = this.props.intl;
			const callbackPositive = () => {
				// go to new project
				const projectSection = this.state.useSprints ? 'sprints' : 'scoping';
				this.props.history.push(
					`${projectUrl(
						result.syncJiraData.projectEdge.node.companyProjectId,
						result.syncJiraData.projectEdge.node.customProjectId
					)}/${projectSection}`
				);
			};
			const SynchCompleteModalStyle = styled.div`
				.synch-complete-modal-title {
					font-size: 20px;
					font-weight: 500;
					color: ${CSS_CONSTANTS.v2_text_gray};
					margin-bottom: 25px;
					display: flex;
				}

				.synch-complete-modal-info {
					font-size: 13px;
					color: #535353;
					margin-bottom: 16px;

					&.bold {
						font-weight: 700;
					}
				}
			`;
			this.props.closeModal();
			if (!this.props.forecastProject) {
				if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
					const startDate = this.state.periodStartDate;

					const periodMutationObject = {
						name: this.props.intl.formatMessage({id: 'retainer.new_period'}),
						projectId: result.syncJiraData.projectEdge.node.id,
					};

					if (startDate) {
						periodMutationObject.startDay = startDate.date();
						periodMutationObject.startMonth = startDate.month() + 1;
						periodMutationObject.startYear = startDate.year();
					}

					if (this.state.preCreatePeriods) {
						periodMutationObject.preCreateAmount = this.state.preCreateAmount;
					}

					Util.CommitMutation(CreateRetainerPeriodMutation, periodMutationObject);
				}

				const jiraProjectName =
					this.props.jiraType === 'cloud'
						? result.syncJiraData.projectEdge.node.jiraCloudProject?.name
						: result.syncJiraData.projectEdge.node.jiraServerProject?.name;
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<SynchCompleteModalStyle>
							<div>
								<div className="synch-complete-modal-title">
									{formatMessage({id: 'jira_app.synch_complete.title'})}
								</div>
								<div className="synch-complete-modal-info">
									{jiraProjectName
										? formatMessage({id: 'jira_app.synch_complete.info'}, {jiraProjectName})
										: formatMessage(
												{id: 'jira_app.synch_complete.epics'},
												{epicCount: result.syncJiraData.projectEdge.node.jiraCloudEpicIds?.length}
										  )}
								</div>
							</div>
						</SynchCompleteModalStyle>
					),
					className: 'default-warning-modal',
					buttons: [
						{
							text: formatMessage({id: 'jira_app.synch_complete.sync_more'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
						{
							text: formatMessage({id: 'common.go_to_project'}),
							callback: callbackPositive,
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					],
				});
			}

			if (this.props.retrieveLinkedEpics && this.state.selectedJiraEpics) {
				this.props.retrieveLinkedEpics(this.state.selectedJiraEpics);
			}
		};

		if (
			(!this.state.selectedJiraProject && !this.state.selectedJiraEpics) ||
			!this.state.forecastProjectName ||
			(this.state.budgetType === BUDGET_TYPE.RETAINER && this.state.defaultPeriodLength < 1) ||
			(this.state.fixedPriceBudget && !parseFloat(this.state.fixedPriceBudget))
		) {
			return;
		}

		const labels = [];
		for (const label of this.state.projectLabels) {
			let isValid = true;
			try {
				atob(label).includes('Label');
			} catch (e) {
				isValid = false;
			}
			if (isValid && atob(label).includes('Label')) {
				labels.push(label);
			}
		}

		const mutationObject = {
			jiraProjectId: this.state.selectedJiraProject,
			jiraEpicIds: this.state.selectedJiraEpics,
			forecastProjectId: this.props.forecastProject ? this.props.forecastProject.id : undefined,
			boardId: this.state.synchronizeSprints ? this.state.selectedJiraBoard?.toString() : undefined,
			subtaskType: this.state.subTaskType,
			epicType: this.state.epicType,
			type: this.props.jiraType,
			estimationField: this.state.useTimeOriginalEstimate ? 'timeoriginalestimate' : this.state.storyPointsOrCustomField,
			name: this.state.forecastProjectName,
			projectColor: this.state.projectColor,
			labels,
			sprintLengthInDays: +this.state.sprintLengthInDays,
			rateCardId: this.state.rateCardSelected ? this.state.rateCardSelected.value : null,
			useManualAllocations: this.state.useManualAllocations,
			budgetType: this.state.budgetType,
			clientId: this.state.selectedClient,
			budget: +this.state.fixedPriceBudget || 0,
			jiraTypeToPhase: this.state.jiraTypeToPhase,
			cutoffDate:
				this.state.useCutoffDate && this.state.cutoffDate != null ? this.state.cutoffDate.format('YYYY-MM-DD') : null,
			useBaseline: this.state.useBaseline,
		};

		if (this.state.projectStartDate) {
			mutationObject.projectStartDay = this.state.projectStartDate.date();
			mutationObject.projectStartMonth = this.state.projectStartDate.month() + 1;
			mutationObject.projectStartYear = this.state.projectStartDate.year();
		}

		if (this.state.projectEndDate) {
			mutationObject.projectEndDay = this.state.projectEndDate.date();
			mutationObject.projectEndMonth = this.state.projectEndDate.month() + 1;
			mutationObject.projectEndYear = this.state.projectEndDate.year();
		}

		if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
			mutationObject.defaultPeriodPriceAmount =
				this.state.defaultPeriodPriceAmount < 0 ? 0 : this.state.defaultPeriodPriceAmount || 0;
			mutationObject.defaultPeriodHoursAmount =
				this.state.defaultPeriodHoursAmount < 0 ? 0 : this.state.defaultPeriodHoursAmount || 0;
			mutationObject.defaultPeriodBudgetType = this.state.defaultPeriodBudgetType;
			mutationObject.defaultPeriodLength = this.state.defaultPeriodLength;
			mutationObject.defaultPeriodPeriodicity = this.state.defaultPeriodPeriodicity;
			if (this.state.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) {
				mutationObject.budget = this.state.defaultPeriodPriceAmount * this.state.preCreateAmount;
			}
		}

		if (this.state.useSprints) {
			mutationObject.sprintTimeBox = !!this.state.selectedJiraBoard;
			mutationObject.sprintLengthInDays = this.state.sprintLengthInDays ? this.state.sprintLengthInDays : 14;
		}

		if (this.state.syncRemaining) {
			mutationObject.remainingField = 'timeestimate';
		}

		if (this.state.useBaseline) {
			mutationObject.useBaseline = this.state.useBaseline;
		}

		Util.CommitMutation(SyncJiraIntegrationMutation, mutationObject, onSuccess);
		this.setState({synching: true});
	}

	selectJiraProjectToSync(selected) {
		if (selected.value !== this.state.selectedJiraProject) {
			const unsynchedJiraProject = this.getUnSynchedProjects().find(jiraProject => jiraProject.id === selected.value);
			const stateChanges = {
				selectedJiraProject: selected.value,
				selectedJiraProjectStyle: unsynchedJiraProject && unsynchedJiraProject.style,
			};
			if (unsynchedJiraProject && unsynchedJiraProject.style === 'next-gen') {
				stateChanges.useTimeOriginalEstimate = false;
			}
			if (!this.props.forecastProject) {
				stateChanges.forecastProjectName = unsynchedJiraProject && unsynchedJiraProject.name;
			}
			this.setState(stateChanges);
		}
	}

	toggleJiraEpic(selected, allSelected) {
		this.setState({selectedJiraEpics: allSelected});
	}

	getUnSynchedProjects() {
		const unsynced =
			this.props.jiraType === 'cloud'
				? this.props.viewer.company.unSynchedJiraProjects
				: this.props.viewer.company.unSynchedJiraServerProjects;

		if (unsynced) return unsynced;
		return [];
	}

	selectClient(selected) {
		if (selected === null) {
			this.setState({selectedClient: null});
		} else {
			this.setState({selectedClient: selected.value});
		}
	}

	selectJiraBoard(selected) {
		if (selected === this.state.selectedJiraBoard) {
			return;
		}
		if (selected === null) {
			this.setState({selectedJiraBoard: null, syncSprintsCount: null});
		} else {
			this.setState({selectedJiraBoard: selected.value});
		}
	}

	onDateRangeChange(startDate, endDate) {
		this.setState({projectStartDate: startDate, projectEndDate: endDate});
	}

	selectSynchronizeSprints(synchronize) {
		if (this.state.synchronizeSprints !== synchronize) {
			const stateObject = {synchronizeSprints: synchronize};
			if (synchronize && !this.state.useSprints) {
				stateObject.useSprints = true;
			}
			this.setState(stateObject);
		}
	}

	selectSyncRemainingTime(sync) {
		if (this.state.syncRemaining !== sync) {
			this.setState({syncRemaining: sync});
		}
	}

	selectUseTimeOriginalEstimate(useTimeOriginalEstimate) {
		if (this.state.useTimeOriginalEstimate !== useTimeOriginalEstimate) {
			this.setState({useTimeOriginalEstimate});
		}
	}

	selectStoryPointsOrCustomField(selected) {
		if (selected.value !== this.state.storyPointsOrCustomField) {
			this.setState({storyPointsOrCustomField: selected.value});
		}
	}

	selectProjectColor(color) {
		this.setState({projectColor: color});
	}

	changeForecastProjectName(value) {
		this.setState({forecastProjectName: value});
	}

	onSubtaskChange(type) {
		this.setState({subTaskType: type});
	}

	onForecastPhasesToJiraTypeChange(type) {
		this.setState({
			jiraTypeToPhase: type,
			epicType: type === 'versions' ? 'task' : 'milestone',
		});
	}

	onJiraEpicsToForecastChange(type) {
		this.setState({epicType: type});
	}

	changePeriodStartDate(start) {
		this.setState({
			periodStartDate: start,
			projectStartDate: start,
			periodStartDateHovered: null,
		});
	}

	setHoveredDay(day) {
		this.setState({
			periodStartDateHovered: day,
		});
	}

	setUseCutoffDate() {
		if (this.state.useCutoffDate) {
			this.setState({useCutoffDate: false, cutoffDate: null});
		} else {
			this.setState({useCutoffDate: true});
		}
	}

	setCutoffDate(cutoffDate) {
		this.setState({cutoffDate});
	}

	getSyncJiraProjectModalContent() {
		const {formatMessage} = this.props.intl;
		const unsynched_jira_project_options = this.getUnSynchedProjects().map(jiraProject => ({
			value: jiraProject.id,
			label: jiraProject.name,
		}));

		const unsynched_jira_epic_options =
			this.state.jiraEpics == null
				? [{value: -1, label: 'Loading...'}]
				: this.state.jiraEpics.map(jiraEpic => ({
						value: jiraEpic.id,
						label: jiraEpic.key + ' ' + jiraEpic.summary,
				  }));
		const client_options = this.props.viewer.company.clients.edges.map(client => ({
			value: client.node.id,
			label: client.node.name,
		}));

		return this.state.synching ? (
			<InlineLoader />
		) : (
			<CustomScrollDiv autoHide={true} autoHeight={true} autoHeightMax={window.innerHeight - 400} autoHeightMin={200}>
				<div className="sync-jira-project-modal">
					<div className="sync-jira-project-modal-section column">
						{this.props.syncType === 'project' ? (
							<DropdownV2
								options={unsynched_jira_project_options}
								customHeight={30}
								onChange={this.selectJiraProjectToSync.bind(this)}
								value={this.state.selectedJiraProject}
								label={'Jira Project'}
							/>
						) : (
							<Dropdown
								isNested
								isMultiSelect
								name={this.state.jiraEpics == null ? 'Loading...' : 'Jira Epics'}
								selectedItems={this.state.selectedJiraEpics}
								onSelect={this.toggleJiraEpic.bind(this)}
								onRemove={this.toggleJiraEpic.bind(this)}
								showSelectedCount
								disabled={this.state.jiraEpics == null}
							>
								<Dropdown.Group name={'Epics'} key={'epic-options'}>
									{unsynched_jira_epic_options.map(option => (
										<Dropdown.SingleText
											key={option.value}
											value={option.value}
											searchString={option.label}
										>
											{option.label}
										</Dropdown.SingleText>
									))}
								</Dropdown.Group>
							</Dropdown>
						)}
						<div className="sync-jira-project-options">
							{
								// <div className={'sync-option'}>
								// 	<span className="count">{this.state.syncTeammembersCount}</span>
								// 	{formatMessage({id: 'common.team_members'})}*
								// </div>
							}
							<div className={'sync-option'}>
								<span className="count">{this.state.syncMilestonesCount}</span>
								{formatMessage({id: 'common.phases'})}
							</div>
							{this.state.synchronizeSprints ? (
								<div className={'sync-option'}>
									<span className="count">{this.state.syncSprintsCount}</span>
									{formatMessage({id: 'common.sprints'})}
								</div>
							) : null}
							{hasFeatureFlag('forecast_epics') && this.state.epicType === 'epic' && (
								<div className={'sync-option'}>
									<span className="count">{this.state.syncEpicEpicCount}</span>
									{formatMessage({id: 'sync_jira_project_modal.epics'})}
								</div>
							)}
							<div className={'sync-option'}>
								<span className="count">{this.state.syncTasksCount}</span>
								{formatMessage({id: 'common.tasks'})}
							</div>
							<div className={'sync-option'}>
								<span className="count">{this.state.syncSubtasksCount}</span>
								{this.state.subTaskType === 'subtasks'
									? formatMessage({id: 'integrations.jira.todos'})
									: formatMessage({id: 'common.sub_tasks'})}
							</div>
						</div>
						<span className="sync-jira-project-info">
							*{formatMessage({id: 'sync_jira_project_modal.synchronize_team_members_info'})}
						</span>
						{this.props.syncType === 'epics' && this.state.unsynchedEpicWorklogs > 0 && (
							<span className="sync-epic-worklog-warning">
								{formatMessage(
									{id: 'app_jira.integrate_page.unsynched_epics'},
									{
										worklogCount: this.state.unsynchedEpicWorklogs,
										epicCount: this.state.selectedJiraEpics.length,
									}
								)}
							</span>
						)}
					</div>
					<div className="inner-sync-jira-project-modal">
						<div className="sync-jira-project-modal-section">
							<div className="column main">
								<InputFieldV2
									id={'forecast-project-title'}
									label={formatMessage({id: 'common.forecast_project_name'})}
									type="text"
									onChange={this.changeForecastProjectName.bind(this)}
									value={this.state.forecastProjectName}
									placeholder={''}
									maxLength={191}
									locked={!!this.props.forecastProject}
									required
								/>
								<div className="dates-controls">
									<DatePickerV3
										disabled={!!this.props.forecastProject}
										isNewDateRange={true}
										startDate={this.state.projectStartDate}
										endDate={this.state.projectEndDate}
										handleDateRangeChange={this.onDateRangeChange.bind(this)}
										datePickerStyle={DATE_PICKER_STYLE.STANDARD}
										clearable={false}
										label={formatMessage({id: 'sync_jira_project_modal.project_date'})}
									/>
								</div>
								<DropdownV2
									customHeight={30}
									options={client_options}
									onChange={this.selectClient.bind(this)}
									clearable
									clearText={formatMessage({id: 'client_selector.no_client_text'})}
									value={this.state.selectedClient ? this.state.selectedClient : null}
									label={formatMessage({id: 'common.client'})}
									disabled={!!this.props.forecastProject}
								/>
							</div>
							<div className="column" style={{alignSelf: 'flex-start'}}>
								<div className={'color-picker-controls'}>
									<span className="controls-label">
										{formatMessage({id: 'project_settings.project-color'})}
									</span>
									<ColorsPicker
										projectLocked={!!this.props.forecastProject}
										value={this.state.projectColor}
										rectangle={true}
										onColorSelect={this.selectProjectColor.bind(this)}
									/>
								</div>
							</div>
						</div>
						{!this.props.forecastProject ? (
							<div
								className={'more-settings'}
								data-cy={'more-settings'}
								onClick={this.togggledetailedSettings.bind(this)}
							>
								<div>
									{this.state.detailedSettingsExpanded
										? formatMessage({id: 'project_settings.fewer_settings'})
										: formatMessage({id: 'project_settings.more_settings'})}
								</div>
								<div className={'arrow' + (this.state.detailedSettingsExpanded ? ' expanded' : '')} />
							</div>
						) : null}
						{this.state.detailedSettingsExpanded ? (
							<NewProjectModalDetailedSettings
								modules={this.props.viewer.company.modules}
								duplicateDisabled={true}
								useSprints={this.state.useSprints}
								errorMessage={this.state.errorMessage}
								sprintLengthInDays={this.state.sprintLengthInDays}
								handleSprintLengthChange={this.handleSprintLengthChange.bind(this)}
								useDuplication={false}
								labels={this.props.viewer.company.labels.edges.map(edge => edge.node)}
								handleCheckboxChange={this.handleDetailedSettingsCheckboxChange.bind(this)}
								useManualAllocations={this.state.useManualAllocations}
								onProjectTypeChange={this.onProjectTypeChange.bind(this)}
								budgetType={this.state.budgetType}
								onBudgetTypeChange={this.onBudgetTypeChange.bind(this)}
								handleFixedPriceBudget={this.handleFixedPriceBudget.bind(this)}
								invalidFixedPriceFormat={this.state.invalidFixedPriceFormat}
								rateCardSelected={this.state.rateCardSelected}
								companyCurrency={this.props.viewer.company.currency}
								fixedPriceBudget={this.state.fixedPriceBudget}
								handleSelectRateChange={this.handleSelectRateChange.bind(this)}
								rateCards={this.props.viewer.company.rateCards.edges}
								onPeriodicityChange={this.onPeriodicityChange.bind(this)}
								defaultPeriodPeriodicity={this.state.defaultPeriodPeriodicity}
								wrongPeriodLength={this.state.wrongPeriodLength}
								defaultPeriodLength={this.state.defaultPeriodLength}
								defaultPeriodBudgetType={this.state.defaultPeriodBudgetType}
								onPeriodBudgetTypeChange={this.onPeriodBudgetTypeChange.bind(this)}
								handlePeriodLengthChange={this.handlePeriodLengthChange.bind(this)}
								handledefaultPeriodChange={this.handledefaultPeriodChange.bind(this)}
								defaultPeriodHoursAmount={this.state.defaultPeriodHoursAmount}
								defaultPeriodPriceAmount={this.state.defaultPeriodPriceAmount}
								projectStartDate={this.state.projectStartDate}
								preCreatePeriods={this.state.preCreatePeriods}
								handleNumberPeriodChange={this.handleNumberPeriodChange.bind(this)}
								onNumberPeriodChangeBlur={this.onNumberPeriodChangeBlur.bind(this)}
								preCreateAmount={this.state.preCreateAmount}
								periodStartDate={this.state.periodStartDate}
								periodStartDateHovered={this.state.periodStartDateHovered}
								createNewLabel={() => {}}
								updateLabels={this.updateLabels.bind(this)}
								projectLabels={this.state.projectLabels}
								canChangeSprintSettings={false}
								availableFeatureFlags={this.props.viewer.availableFeatureFlags}
								tier={this.props.viewer.company.tier}
								setHoveredDay={this.setHoveredDay.bind(this)}
								changePeriodStartDate={this.changePeriodStartDate.bind(this)}
								useBaseline={this.state.useBaseline}
							/>
						) : null}
					</div>
					<div className="inner-sync-jira-project-modal">
						<div className="sync-jira-project-modal-section">
							<div className="column main">
								<div className="radio-button-controls">
									<span className="controls-label">
										{formatMessage({id: 'sync_jira_project_modal.synchronize_sprints'})}
									</span>
									<RadioButton
										text={formatMessage({id: 'common.yes'})}
										onSelected={this.selectSynchronizeSprints.bind(this, true)}
										value={true}
										stateValue={this.state.synchronizeSprints}
									/>
									<RadioButton
										text={formatMessage({id: 'common.no'})}
										onSelected={this.selectSynchronizeSprints.bind(this, false)}
										value={false}
										stateValue={this.state.synchronizeSprints}
									/>
								</div>
								{this.state.synchronizeSprints ? (
									<>
										<DropdownV2
											customHeight={30}
											options={this.state.jira_board_options}
											onChange={this.selectJiraBoard.bind(this)}
											value={this.state.selectedJiraBoard ? this.state.selectedJiraBoard : null}
											label={'Jira Board'}
											requried
										/>
										{!this.state.boardsDeepFetched ? (
											<div onClick={this.fetchJiraBoards.bind(this, true)} className="deep-fetch-boards">
												<FormattedMessage id="sync_jira_project_modal.missing_board" />
											</div>
										) : null}
									</>
								) : null}

								<div className="radio-button-controls">
									<span className="controls-label">
										{formatMessage({id: 'integrations.jira.sync_remaining'})}
									</span>
									<RadioButton
										text={formatMessage({id: 'common.yes'})}
										onSelected={this.selectSyncRemainingTime.bind(this, true)}
										value={true}
										stateValue={this.state.syncRemaining}
									/>
									<RadioButton
										text={formatMessage({id: 'common.no'})}
										onSelected={this.selectSyncRemainingTime.bind(this, false)}
										value={false}
										stateValue={this.state.syncRemaining}
									/>
								</div>

								<div className="cutoff-date-controls">
									<div className="radio-button-controls">
										<div className="cutoff-title">
											<span className="controls-label">
												{formatMessage({id: 'sync_jira_project_modal.cutoff_date_title'})}
											</span>
											<ForecastTooltip
												content={formatMessage({
													id: 'sync_jira_project_modal.cutoff_date_tooltip',
												})}
												delay={0}
											>
												<InformationIcon />
											</ForecastTooltip>
										</div>
										<RadioButton
											text={formatMessage({id: 'common.yes'})}
											onSelected={this.setUseCutoffDate.bind(this, true)}
											value={true}
											stateValue={this.state.useCutoffDate}
										/>
										<RadioButton
											text={formatMessage({id: 'common.no'})}
											onSelected={this.setUseCutoffDate.bind(this, false)}
											value={false}
											stateValue={this.state.useCutoffDate}
										/>
									</div>
									{this.state.useCutoffDate ? (
										<div className={'cutoff-date-selector'}>
											<div className="cutoff-date-description">
												{formatMessage({id: 'sync_jira_project_modal.cutoff_date_picker_title'})}
											</div>
											{this.state.useCutoffDate && this.state.cutoffDate == null ? (
												<div className="cutoff-date-error-description">
													{formatMessage({
														id: 'sync_jira_project_modal.cutoff_date_error_description',
													})}
												</div>
											) : null}
											<div className={'cutoff-date-picker'}>
												<DatePickerV3
													startDate={this.state.cutoffDate}
													handleDateRangeChange={this.setCutoffDate.bind(this)}
													datePickerStyle={DATE_PICKER_STYLE.STANDARD}
													isSingleDatePicker={true}
												/>
											</div>
										</div>
									) : null}
								</div>
								<div className="radio-button-controls">
									<span className="controls-label">Estimate Type</span>
									<RadioButton
										text="Time Original Estimate"
										onSelected={this.selectUseTimeOriginalEstimate.bind(this, true)}
										value={true}
										stateValue={this.state.useTimeOriginalEstimate}
									/>
									<RadioButton
										text="Story Points / Custom Field"
										onSelected={this.selectUseTimeOriginalEstimate.bind(this, false)}
										value={false}
										stateValue={this.state.useTimeOriginalEstimate}
									/>
									{this.state.useTimeOriginalEstimate ? null : (
										<DropdownV2
											customHeight={30}
											options={this.state.custom_field_options}
											onChange={this.selectStoryPointsOrCustomField.bind(this)}
											value={this.state.storyPointsOrCustomField}
											hideLabel
											placeholder="Select story points/custom field"
										/>
									)}
								</div>
								{this.props.syncType === 'project' ? (
									<>
										<div className="radio-button-controls">
											<span className="controls-label">
												<FormattedMessage id="sync_jira_project_modal.phase.label" />
											</span>
											{this.state.epicType === 'milestone' && this.state.unsynchedEpicWorklogs > 0 && (
												<span className="sync-epic-worklog-warning">
													{formatMessage(
														{id: 'app_jira.integrate_page.unsynched_epics'},
														{
															worklogCount: this.state.unsynchedEpicWorklogs,
															epicCount: this.state.selectedJiraEpics.length,
														}
													)}
												</span>
											)}
											<RadioButton
												text={formatMessage({id: 'sync_jira_project_modal.epics'})}
												value={'epics'}
												onSelected={this.onForecastPhasesToJiraTypeChange.bind(this)}
												stateValue={this.state.jiraTypeToPhase}
											/>
											<RadioButton
												text={formatMessage({id: 'sync_jira_project_modal.versions'})}
												value={'versions'}
												onSelected={this.onForecastPhasesToJiraTypeChange.bind(this)}
												stateValue={this.state.jiraTypeToPhase}
											/>
											<span className="sync-jira-project-info">
												{/*This will be translated later, when we have the final text*/}
												Phases are a way we group tasks in Forecast. Once synced, tasks will be grouped
												automatically into Phases
											</span>
										</div>
										{hasFeatureFlag('forecast_epics') && this.state.jiraTypeToPhase === 'versions' && (
											<div className="radio-button-controls">
												<span className="controls-label">
													<FormattedMessage id="sync_jira_project_modal.epic.label" />
												</span>
												<RadioButton
													text={'Epics'}
													value={'epic'}
													onSelected={this.onJiraEpicsToForecastChange.bind(this)}
													stateValue={this.state.epicType}
												/>
												<RadioButton
													text={'Tasks'}
													value={'task'}
													onSelected={this.onJiraEpicsToForecastChange.bind(this)}
													stateValue={this.state.epicType}
												/>
												<span className="sync-jira-project-info">
													{/*This will be translated later, when we have the final text*/}
													Jira Epics will either sync as Forecast Epics (currently hidden but will be
													released soon) or to Forecast Tasks
												</span>
											</div>
										)}
									</>
								) : null}
								<div className="radio-button-controls">
									<span className="controls-label">
										<FormattedMessage id="settings.app_catalog.jira.subtasks.label" />
									</span>
									<RadioButton
										text={formatMessage({id: 'sync_jira_project_modal.tasks_new_subtasks'})}
										value={'cards'}
										onSelected={this.onSubtaskChange.bind(this)}
										stateValue={this.state.subTaskType}
									/>
									<RadioButton
										text={formatMessage({id: 'common.to_do'})}
										value={'subtasks'}
										onSelected={this.onSubtaskChange.bind(this)}
										stateValue={this.state.subTaskType}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CustomScrollDiv>
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const buttons = [
			{
				text: formatMessage({id: 'common.cancel'}),
				cy: 'skip-allocation-button',
				callback: this.props.closeModal,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				disabled: this.state.synching,
			},
			{
				text: 'sync project',
				callback: this.syncProject.bind(this),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				disabled:
					(!this.state.selectedJiraProject && !this.state.selectedJiraEpics?.length) ||
					this.state.synching ||
					this.state.errorMessage ||
					(this.state.useCutoffDate && this.state.cutoffDate == null),
				preventDefaultClose: true,
			},
		];
		const headerText =
			this.props.syncType === 'project' && (this.props.jiraProject || this.props.forecastProject)
				? formatMessage({id: 'app_jira.sync_jira_project'})
				: this.props.syncType === 'epics'
				? formatMessage({id: 'app_jira.sync_jira_epics'})
				: formatMessage({id: 'app_jira.sync_first_jira_project'});
		return (
			<GenericModal
				useScrollbars={false}
				closeModal={this.props.closeModal}
				headerText={headerText}
				buttons={buttons}
				content={this.getSyncJiraProjectModalContent()}
			/>
		);
	}
}

syncJiraProjectModal.propTypes = {
	jiraProject: PropTypes.object,
	jiraEpics: PropTypes.arrayOf(PropTypes.object),
	forecastProject: PropTypes.object,
	jiraType: PropTypes.oneOf(['cloud', 'server']).isRequired,
	syncType: PropTypes.oneOf(['project', 'epics']).isRequired,
	closeModal: PropTypes.func.isRequired,
};

const syncJiraProjectModalQuery = graphql`
	query syncJiraProjectModal_Query($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "sync_jira_project_modal")
			...syncJiraProjectModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {syncJiraProjectModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(syncJiraProjectModal, {
			viewer: graphql`
				fragment syncJiraProjectModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						tier
						currency
						modules {
							moduleType
						}
						defaultRateCard {
							id
						}
						unSynchedJiraProjects {
							id
							name
							style
						}
						unSynchedJiraServerProjects {
							id
							name
						}
						clients(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						rateCards(first: 10000) {
							edges {
								node {
									id
									name
									currency
								}
							}
						}
						labels(first: 10000, labelType: PROJECT) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
