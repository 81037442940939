import React, {useEffect, useState} from 'react';
import {BasicTextInput, Button, ColorPicker, Dropdown, HexagonText} from 'web-components';
import DateRangePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import RichTextField from '../../../components/new-ui/rich_text_field';

import {BUDGET_TYPE, DATE_PICKER_STYLE, HIDDEN_FEATURES} from '../../../constants';
import {getDefaultSmallClientLogo} from '../../../components/default_avatars';
import {DatesWrapper, InputLineInput, InputLineLabel, InputLineWrapper, SubSection} from './ProjectSettings_styled';
import {Text} from '@forecast-it/design-system';
import LabelDropdown from '../../../components/dropdowns/LabelDropdown/LabelDropdown';
import {
	changeClient,
	changeCustomProjectId,
	changeDescription,
	changeEndDate,
	changeName,
	changeProjectColor,
	changeStartDate,
	updateLabels,
} from './ProjectSettingsMutations';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import DeleteProjectMutation from '../../../mutations/delete_project_mutation';
import UpdateCustomFieldValueMutation from '../../../mutations/update_custom_field_value_mutation';
import ValidateProjectIdMutation from '../../../mutations/ValidateProjectIdMutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import DirectApi, {clientLogoSrc, projectUrl} from '../../../directApi';
import {useRecentProjects} from '../../../forecast-app/project-tab/projects/hooks/useRecentProjects';
import {isDateDisabled} from '../../../forecast-app/shared/util/DateUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const ProjectSettingsGeneral = ({
	intl,
	history,
	projectId,
	companyProjectId,
	customProjectId,
	projectGroupId,
	projectName,
	projectDoneOrHalted,
	projectDescription,
	projectStartDate,
	projectEndDate,
	companyClients,
	projectClient,
	companyLabels,
	projectLabels,
	customFieldValues,
	projectColor,
	projectPersonsName,
	hasLockedTime,
	hasInvoice,
	budgetType,
	pipedriveCompanyDomain,
	hubspotAccountId,
	userCanDeleteProject,
	userCantDeleteProjectReason,
	isUsingProjectAllocation,
	isSageProject,
}) => {
	customFieldValues = customFieldValues?.edges.map(edge => {
		return {...edge.node};
	});
	/**
	 * FIXME: Having all these intermediate states, means that we are unable to respond to changes from socket events,
	 * since the state is stored locally and never updated unless changed from controls on this page.
	 * Having a direct link to the field pulled through Relay and using optimistic updates in the relevant mutations
	 * to immediately change this value is a more robust pattern.
	 * */

	const [_customFieldValues, setCustomFieldValues] = useState(customFieldValues || []);
	const [_projectName, setProjectName] = useState(projectName);
	const [_customProjectId, setCustomProjectId] = useState(customProjectId);
	const [customProjectIdError, setCustomProjectIdError] = useState('');
	const [_projectColor, setProjectColor] = useState(projectColor);
	const {formatMessage} = intl;
	const labels = projectLabels.map(projectLabel => projectLabel.node.label);
	const [labelIds, setLabelIds] = useState(labels.map(label => label.id));
	const setSelectedLabelIdsHandler = newLabelIds => {
		setLabelIds(newLabelIds);
		updateLabels(projectId, newLabelIds);
	};

	const [showOverlay, setShowOverlay] = useState(false);

	const recentProjects = useRecentProjects();

	const handleChangeName = name => {
		if (!/\S/.test(name)) {
			setProjectName(projectName);
			return;
		}
		changeName(projectId, name);
	};

	const handleChangeCustomProjectId = newValue => {
		const doValidation = newValue.toLowerCase() !== customProjectId.toLowerCase();
		const doUpdate = newValue !== customProjectId;

		if (doValidation || doUpdate) {
			if (newValue === '') {
				// If value is empty send empty string and let backend set id
				setCustomProjectIdError('');
				changeCustomProjectId(projectId, '');
				return;
			}
			if (!newValue.match(/^[a-z0-9-]+$/i)) {
				setCustomProjectIdError(formatMessage({id: 'new_project_modal.project_id_error.alphanumeric'}));
				return;
			}
			if (doValidation && doUpdate) {
				Util.CommitMutation(ValidateProjectIdMutation, {projectId: newValue}, result => {
					if (result.validateProjectId.valid === 'true') {
						setCustomProjectIdError('');
						showModal(
							{
								type: MODAL_TYPE.CONFIRMATION,
								title: formatMessage({id: 'confirmation.confirm_project_id_change'}),
								description: formatMessage({id: 'confirmation.confirm_project_id_change_description'}),
								callbackPositive: () => changeCustomProjectId(projectId, newValue),
								callbackNegative: () => setCustomProjectId(customProjectId),
							},
							true
						);
					} else {
						setCustomProjectIdError(formatMessage({id: 'new_project_modal.project_id_error.already_in_use'}));
					}
				});
			} else if (doUpdate) {
				changeCustomProjectId(projectId, newValue);
			}
		}
	};

	useEffect(() => {
		setProjectName(projectName);
	}, [projectName]);

	useEffect(() => {
		setProjectColor(projectColor);
	}, [projectColor]);

	useEffect(() => {
		setCustomProjectId(customProjectId);
	}, [customProjectId]);

	const changeCustomFieldValue = (customField, value) => {
		_customFieldValues.find(cf => cf.id === customField.id).value = value;
		setCustomFieldValues([..._customFieldValues]);
	};

	const handleCustomFieldChange = (customField, value) => {
		Util.CommitMutation(UpdateCustomFieldValueMutation, {
			id: customField.id,
			entityId: projectId,
			entityType: 'PROJECT',
			key: customField.key,
			value,
		});
	};

	const handleChangeProjectColor = color => {
		let formatedHex = color && color.replace('#', '') + '';
		if (formatedHex && formatedHex.length === 6 && !isNaN(Number('0x' + formatedHex))) {
			setProjectColor(color);
			changeProjectColor(projectId, color);
		}
	};

	const deleteProject = () => {
		const callbackPositive = params => {
			const onSuccess = result => {
				if (
					result.deleteProject &&
					result.deleteProject.errors &&
					result.deleteProject.errors.length === 1 &&
					result.deleteProject.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD'
				) {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
						nonTimeRegWarning: true,
					});
				} else {
					const headers = new Headers();
					headers.append('Content-Type', 'application/json');

					const init = {
						headers: headers,
						credentials: 'include',
						method: 'DELETE',
					};

					if (pipedriveCompanyDomain) {
						fetch(DirectApi.graphqlServerEndpoint(`pipedrive/project_templates?projectIds=${projectId}`), init);
					}
					if (hubspotAccountId) {
						fetch(DirectApi.graphqlServerEndpoint(`hubspot/project_templates?projectIds=${projectId}`), init);
					}
					createToast({
						duration: 5000,
						message: formatMessage({id: 'settings.has-been-deleted'}),
					});

					// Remove from recent projects
					recentProjects.projectDeleted(projectId);

					history.push('/projects');
				}
			};
			setShowOverlay(true);
			Util.CommitMutation(
				DeleteProjectMutation,
				{
					id: projectId,
					projectGroupId: projectGroupId,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.CONFIRM_PROJECT_DELETION,
			confirmText: formatMessage({id: 'settings.confirm'}),
			onDelete: callbackPositive,
		});
	};

	const showDuplicateProjectModal = () => {
		const onSuccess = res => {
			history.push(
				projectUrl(
					res.duplicateProject.project.node.companyProjectId,
					res.duplicateProject.project.node.customProjectId
				) + '/settings'
			);
			document.getElementById('project_title').focus();
		};
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
			projectToDuplicateId: projectId,
			isUsingProjectAllocation: isUsingProjectAllocation,
			onSuccess,
		});
	};

	const cannotDelete =
		hasLockedTime ||
		hasInvoice ||
		(budgetType === BUDGET_TYPE.RETAINER && !hasPermission(PERMISSION_TYPE.RETAINER_PERIOD_DELETE)) ||
		!userCanDeleteProject;

	const cannotDeleteReason = () => {
		if (hasLockedTime || userCantDeleteProjectReason === 'HAS_LOCKED_TIME') {
			return formatMessage({id: 'project.cant_delete_reason.locked_time_regs'});
		} else if (hasInvoice || userCantDeleteProjectReason === 'HAS_INVOICED_TIME') {
			return formatMessage({id: 'project.cant_delete_reason.invoice_time_regs'});
		} else if (!userCanDeleteProject) {
			return formatMessage({id: 'project.cant_delete_reason.other_peoples_time_regs'});
		} else {
			return null;
		}
	};

	const duplicateButton = (
		<Button
			size={Button.SIZE.STANDARD}
			variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
			onClick={showDuplicateProjectModal}
		>
			{formatMessage({id: 'project_settings.duplicate-project'})}
		</Button>
	);

	const deleteButton = (
		<ForecastTooltip content={cannotDelete ? cannotDeleteReason() : null}>
			<Button
				size={Button.SIZE.STANDARD}
				variant={Button.VARIANT.RED_OUTLINE}
				onClick={!cannotDelete && deleteProject}
				disabled={cannotDelete}
			>
				{formatMessage({id: 'project-settings.gear-menu.delete-project'})}
			</Button>
		</ForecastTooltip>
	);
	return (
		<>
			<SubSection title={formatMessage({id: 'project_settings.project_details'})}>
				{showOverlay && <UploadingOverlay />}
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'common.project-name'})}</InputLineLabel>
					<InputLineInput>
						{/*maxLength={191} for an obsucre reason*/}
						<BasicTextInput
							value={_projectName}
							onChange={setProjectName}
							callback={handleChangeName}
							disabled={projectDoneOrHalted}
							maxLength={191}
							cy={'project-name-input'}
						/>
					</InputLineInput>
				</InputLineWrapper>
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'common.description'})}</InputLineLabel>
					<InputLineInput>
						<RichTextField
							textCy={'project-description-text'}
							cy={'project-description-container'}
							readOnly={projectDoneOrHalted}
							alwaysShowControls={false}
							text={projectDescription}
							//	characterLimit={this.props.viewer.company.characterLimit}
							placeholder={formatMessage({
								id: 'settings.project-edit-description',
							})}
							handleTextChange={description => changeDescription(projectId, description)}
							mentions={projectPersonsName}
							mentionsLabels={companyLabels}
						/>
					</InputLineInput>
				</InputLineWrapper>
				{hasFeatureFlag('custom_project_ids') ? (
					<InputLineWrapper>
						<InputLineLabel>{formatMessage({id: 'new_project_modal.project_id'})}</InputLineLabel>
						<InputLineInput>
							<BasicTextInput
								value={_customProjectId}
								onChange={setCustomProjectId}
								callback={handleChangeCustomProjectId}
								disabled={projectDoneOrHalted || isSageProject}
								maxLength={6}
								cy={'project-id-input'}
								placeholder={companyProjectId ? `P${companyProjectId}` : undefined}
							/>
							{customProjectIdError && <Text color={'error'}>{customProjectIdError}</Text>}
						</InputLineInput>
					</InputLineWrapper>
				) : null}

				{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) ? (
					<InputLineWrapper>
						<InputLineLabel>{formatMessage({id: 'common.client'})}</InputLineLabel>
						<InputLineInput>
							<Dropdown
								isClearable
								selectedItems={[projectClient]}
								onSelect={clientId => changeClient(projectId, clientId[0])}
								onRemove={() => changeClient(projectId, null)}
								name={formatMessage({id: 'empty_state_project_client.select-placeholder'})}
								disabled={projectDoneOrHalted}
							>
								{companyClients.map(client => (
									<Dropdown.SingleText
										key={client.node.id}
										value={client.node.id}
										searchString={client.node.name}
									>
										<HexagonText
											key={client.node.id}
											value={client.node.id}
											searchString={client.node.name}
											text={client.node.name}
											imgUrl={
												client.node.logoId
													? clientLogoSrc(client.node.logoId)
													: getDefaultSmallClientLogo(0)
											}
										/>
									</Dropdown.SingleText>
								))}
							</Dropdown>
						</InputLineInput>
					</InputLineWrapper>
				) : null}
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'project_overview.project_dates'})}</InputLineLabel>
					<InputLineInput>
						<DatesWrapper>
							<DateRangePicker
								clearBothDates={() => changeStartDate(projectId, projectEndDate, null)}
								isSingleDatePicker={true}
								startDate={projectStartDate}
								handleDateRangeChange={startDate => changeStartDate(projectId, projectEndDate, startDate)}
								isNewDateRange={true}
								datePickerStyle={DATE_PICKER_STYLE.SLIM}
								highlighted={true}
								clearable={true}
								disabled={projectDoneOrHalted}
								endDateLimite={projectEndDate}
								hideLimitMessage
								disabledDates={isDateDisabled}
							/>
							<DateRangePicker
								clearBothDates={() => changeEndDate(projectId, projectStartDate, null)}
								isSingleDatePicker={true}
								startDate={projectEndDate}
								handleDateRangeChange={endDate => changeEndDate(projectId, projectStartDate, endDate)}
								isNewDateRange={true}
								datePickerStyle={DATE_PICKER_STYLE.SLIM}
								highlighted={true}
								clearable={true}
								disabled={projectDoneOrHalted}
								startDateLimite={projectStartDate}
								hideLimitMessage
								disabledDates={isDateDisabled}
							/>
						</DatesWrapper>
					</InputLineInput>
				</InputLineWrapper>
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'project_settings.project_labels'})}</InputLineLabel>
					<InputLineInput>
						<LabelDropdown
							labels={companyLabels}
							selectedItems={labelIds}
							setSelectedItems={setSelectedLabelIdsHandler}
							selectedGroupName={formatMessage({id: 'common.added'})}
							name={formatMessage({id: 'settings_labels.add-label'})}
							disabled={projectDoneOrHalted}
							data-cy={'project-labels-dropdown'}
						/>
					</InputLineInput>
				</InputLineWrapper>
				{_customFieldValues.map(customField => {
					return (
						<InputLineWrapper key={'custom_field_key_' + customField.id}>
							<InputLineLabel>{customField.displayName}</InputLineLabel>
							<InputLineInput>
								<BasicTextInput
									value={customField.value}
									onChange={changeCustomFieldValue.bind(null, customField)}
									callback={handleCustomFieldChange.bind(null, customField)}
									disabled={customField.readOnly}
									maxLength={191}
									cy={'custom-field-name-input-' + customField.displayName}
								/>
							</InputLineInput>
						</InputLineWrapper>
					);
				})}
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'project_settings.project-color'})}</InputLineLabel>
					<InputLineInput>
						<ColorPicker
							chooseColor={handleChangeProjectColor}
							selectedColor={_projectColor}
							showHexPicker={true}
						/>
					</InputLineInput>
				</InputLineWrapper>
			</SubSection>
			<SubSection
				title={formatMessage({id: 'project_settings.duplicate-project'})}
				actionElement={hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && duplicateButton}
			></SubSection>
			<SubSection
				title={formatMessage({id: 'project-settings.gear-menu.delete-project'})}
				actionElement={hasPermission(PERMISSION_TYPE.PROJECTS_DELETE) && deleteButton}
			></SubSection>
		</>
	);
};

export default ProjectSettingsGeneral;
