import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {MODULE_TYPES, SOCKET_ACTION, SOCKET_EVENT_TYPE, TIERS} from '../constants';
import {hasPermission, setPermissions} from '../forecast-app/shared/util/PermissionsUtil';
import {setTimeRegistrationSettings} from '../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';
import {PERMISSION_TYPE} from '../Permissions';
import moment from 'moment';
import Util from '../forecast-app/shared/util/util';
import {hasFeatureFlag, setAvailableFeatureFlags} from '../forecast-app/shared/util/FeatureUtil';
import {hasModule, setEnabledModules} from '../forecast-app/shared/util/ModuleUtil';
import {setAvailableMLModels} from '../forecast-app/shared/util/AvailableMLModelUtil';
import {withSocketHandling} from '../socket/withSocketHandling';
import {setCompanySetup} from '../forecast-app/shared/util/CompanySetupUtil';
import SlideoutConductor from '../forecast-app/shared/components/slideout/SlideoutConductor';

import {ForecastThemeProvider} from '../forecast-app/shared/ForecastThemeProvider';

class appv2 extends Component {
	constructor(props) {
		super(props);

		Util.setCsrfValue(props.viewer.csrfToken);
		setPermissions(props.viewer.permissions);
		setAvailableFeatureFlags(props.viewer.availableFeatureFlags);
		setAvailableMLModels(props.viewer.company.availableMLModels);
		setEnabledModules(props.viewer.company.modules);
		setCompanySetup(props.viewer.company);
		setTimeRegistrationSettings(props.viewer.company.timeRegistrationSettings);
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.downForMaintenance) {
			this.props.history.push({
				pathname: '/maintenance',
			});
			return;
		}

		if (this.props.viewer.actualPersonId !== null) {
			const isTrial = this.props.viewer.company?.tier === TIERS.TRIAL;
			const isOnTrialEnd = this.props.history.location.pathname === '/trial-end';
			const trialEndTime = moment.utc(this.props.viewer.company.trialEndTime);
			const now = moment.utc();
			if (isTrial && !isOnTrialEnd && trialEndTime.isBefore(now)) {
				this.props.history.push('/trial-end');
			}
		}

		this.props.setLocale && this.props.setLocale(this.props.viewer.language);
	}

	componentDidMount() {
		if (this.props.viewer.actualPersonId === null) {
			this.props.history.push({
				pathname: '/login',
			});
		} else if (hasFeatureFlag('accept_terms_sso') && !this.props.viewer.hasAcceptedTerms) {
			this.props.history.push({
				pathname: '/accept-terms',
				state: {nextPathname: this.props.location.pathname},
			});
		} else if (
			this.props.viewer &&
			this.props.viewer.company &&
			this.props.viewer.company.accessBlocked &&
			!['/trial-end', '/access-blocked', '/settings/subscription'].includes(this.props.history.location.pathname)
		) {
			this.props.history.push('/access-blocked');
		}

		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.MODULE,
				action: SOCKET_ACTION.UPDATE,
			},
			{
				type: SOCKET_EVENT_TYPE.FEATURE_FLAG,
				action: SOCKET_ACTION.UPDATE,
				personIds: [this.props.viewer.backendId],
			},
			{
				type: SOCKET_EVENT_TYPE.PERMISSION,
				action: SOCKET_ACTION.UPDATE,
				personIds: [this.props.viewer.backendId],
			},
			{
				type: SOCKET_EVENT_TYPE.TIME_REGISTRATION_SETTINGS,
				action: SOCKET_ACTION.UPDATE,
			},
		];
		this.props.setSocketConfig(socketEvents);
	}

	componentDidUpdate(prevProps) {
		// Check necessary to prevent infinite update
		if (
			this.props.viewer.actualPersonId === null &&
			(!this.props.location.state || this.props.location.state.nextPathname !== '/login')
		) {
			this.props.history.push({
				pathname: '/login',
				state: {nextPathname: this.props.location.pathname},
			});
		}

		setPermissions(this.props.viewer.permissions);
		setAvailableFeatureFlags(this.props.viewer.availableFeatureFlags);
		setEnabledModules(this.props.viewer.company?.modules);
		setCompanySetup(this.props.viewer.company);
		setTimeRegistrationSettings(this.props.viewer.company?.timeRegistrationSettings);

		// Redirect to sage subdomain if needed
		if (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			if (window.location.hostname === 'testv2.wolwa.com') {
				window.location.href = `https://sage.wolwa.com${window.location.pathname}`;
			} else if (window.location.hostname === 'app.forecast.it') {
				window.location.href = `https://sage.forecast.it${window.location.pathname}`;
			}
		}
	}

	render() {
		if (this.props.viewer.actualPersonId === null || this.props.viewer.company === null) {
			return <div />;
		}

		const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
		const isTrial = this.props.viewer.company.tier === TIERS.TRIAL;
		const isOnTrialEnd = this.props.history.location.pathname === '/trial-end';
		const trialEndTime = this.props.viewer.company.trialEndTime;

		const buyNowTime = isAdmin && isTrial && !isOnTrialEnd && trialEndTime;

		return (
			<ForecastThemeProvider>
				{this.props.appContent(
					this.props.viewer.actualPersonId,
					this.props.viewer.availableFeatureFlags,
					this.props.viewer.company.modules,
					buyNowTime,
					this.props.viewer.company.tier
				)}
				<SlideoutConductor />
			</ForecastThemeProvider>
		);
	}
}

const appv2Query = graphql`
	query appv2_Query {
		viewer {
			actualPersonId
			component(name: "appv2")
			...appv2_viewer
		}
	}
`;

export {appv2Query};

export default createFragmentContainer(withSocketHandling(withRouter(appv2)), {
	viewer: graphql`
		fragment appv2_viewer on Viewer {
			id
			backendId
			csrfToken
			actualPersonId
			permissions
			hasAcceptedTerms
			downForMaintenance
			availableFeatureFlags {
				key
			}
			email
			language
			company {
				id
				accessBlocked
				trialEndTime
				tier
				modules {
					endYear
					endMonth
					endDay
					moduleType
				}
				availableMLModels {
					lowHighModel
					overrunModel
					roleModel
					assigneeModel
					labelModel
				}
				timeRegistrationSettings {
					granularity
					allowDoneTasks
					allowProjects
					allowInternalTime
					allowBillableSplit
					allowRoles
					allowExceedingEstimate
					allowOutsideTaskDates
					allowOutsideProjectDates
					requireTaskAssignment
					requireNote
				}
				revenueEnabled
				timesheetsEnabled
				clientsEnabled
				weekendDisplayPerUser
				weekendDisplayShowAlways
			}
		}
	`,
});
