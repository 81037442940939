import Util from '../../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';
import UpdateTaskMutation from '../../../mutations/update_task_mutation.modern';
import UpdateProjectGroupMutation from '../../../mutations/update_project_group_mutation';
import UpdateInitialPlanProjectMutation from '../../../mutations/UpdateInitialPlanProjectMutation';
import {showModal, MODAL_TYPE} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

export const updateProjectOnSuccess = res => {
	const errors = res.updateProject.errors;
	if (errors && errors.length === 1) {
		Util.checkForSageErrorAndShowModal(errors);
	}
};

export const changeName = (projectId, name) => {
	const project = {id: projectId};

	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			name: name,
		},
		updateProjectOnSuccess
	);
};

const handleChangeClientMutationResult = result => {
	const resultType = result.updateProject;
	if (resultType && resultType.errors && resultType.errors.length === 1) {
		const error = resultType.errors[0];
		if (error === 'CLIENT_CHANGE_EXPORTED_INVOICE_BILL_TO_MISMATCH') {
			showModal({
				type: MODAL_TYPE.WARNING_CLIENT_CHANGE_EXPORTED_INVOICE_BILL_TO_MISMATCH,
			});
		}
		updateProjectOnSuccess(result);
	}
};

export const changeClient = (projectId, clientId) => {
	const project = {id: projectId};
	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			clientId: clientId,
		},
		handleChangeClientMutationResult
	);
};

export const updateLabels = (projectId, newLabelIds) => {
	Util.CommitMutation(UpdateProjectMutation, {
		project: {id: projectId},
		labels: newLabelIds,
	});
};

export const changeCustomProjectId = (projectId, customProjectId) => {
	const project = {id: projectId};
	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			customProjectId: customProjectId,
		},
		res => {
			trackEvent('Custom Project Id', 'Changed', {value: customProjectId});
			window.history.replaceState(project, '', `/project/${res.updateProject.project.customProjectId}/settings`);
		}
	);
};

export const changeProjectColor = (projectId, color) => {
	const project = {id: projectId};
	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		projectColor: color,
	});
};

export const changeDescription = (projectId, description) => {
	const project = {id: projectId};
	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			description: description,
			//labels: this.state.selectedLabels,
		},
		updateProjectOnSuccess
	);
};

export const changeStartDate = (projectId, projectEndDate, startDate) => {
	const project = {id: projectId};

	if (startDate && projectEndDate && startDate.isAfter(projectEndDate)) return;
	if (startDate) {
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project: project,
				projectStartYear: startDate.year(),
				projectStartMonth: startDate.month() + 1,
				projectStartDay: startDate.date(),
			},
			updateProjectOnSuccess
		);
	} else {
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project: project,
				projectStartYear: null,
				projectStartMonth: null,
				projectStartDay: null,
				projectEndYear: null,
				projectEndMonth: null,
				projectEndDay: null,
			},
			updateProjectOnSuccess
		);
	}
};

export const changeEndDate = (projectId, projectStartDate, endDate) => {
	const project = {id: projectId};

	if (projectStartDate && endDate && projectStartDate.isAfter(endDate)) return;
	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			projectEndYear: endDate ? endDate.year() : null,
			projectEndMonth: endDate ? endDate.month() + 1 : null,
			projectEndDay: endDate ? endDate.date() : null,
		},
		updateProjectOnSuccess
	);
};

export const changeStage = (projectId, stage) => {
	const project = {id: projectId};

	Util.CommitMutation(
		UpdateProjectMutation,
		{
			project: project,
			status: stage,
		},
		updateProjectOnSuccess
	);
};

export const changeWinChance = (projectId, winChance) => {
	if (winChance >= 0 && winChance <= 1) {
		Util.CommitMutation(UpdateInitialPlanProjectMutation, {
			id: projectId,
			baselineWinChance: winChance,
		});
	}
};

export const changeTasksColumn = (notDoneIds, statusColumnId, statusColumnCategory) => {
	let chunkArray = Util.chunkArray(notDoneIds, 100);
	chunkArray.forEach(chunkIds => {
		Util.CommitMutation(UpdateTaskMutation, {
			ids: chunkIds,
			statusColumnId: statusColumnId,
			statusColumnCategory: statusColumnCategory,
		});
	});
};

export const changeUseSprint = (projectId, useSprint) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		sprintTimeBox: !useSprint,
	});
};

export const changeSprintLenght = (projectId, sprintLength) => {
	if (sprintLength < 1) return;
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		sprintLengthInDays: sprintLength,
	});
};

export const changeTaskSubLevel = (projectId, taskSubLevel) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		taskLevels: taskSubLevel,
	});
};

export const changeTaskMonitoring = (projectId, option) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		useTaskOwner: option === 'owner',
		useTaskFollowers: option === 'followers',
	});
};

export const changeEstimationUnit = (projectId, unit) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		estimationUnit: unit,
	});
};

export const changeRamainingCalculations = (projectId, isAutomatic) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		remainingAutoCalculated: isAutomatic,
	});
};

export const changeUsePublicCreateTask = (projectId, linkToPublicCreateTaskPage) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		usePublicCreateTaskPage: linkToPublicCreateTaskPage === null,
	});
};

export const changeUsePublicCreateTaskPassword = (projectId, usePublicCreateTaskPassword) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		usePublicCreateTaskPassword: !usePublicCreateTaskPassword,
	});
};

export const changePublicCreateTaskPassword = (projectId, password) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		publicCreateTaskPassword: password,
	});
};

export const changeUseTaskHierarchy = (projectId, useTaskHierarchy) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		useTaskHierarchy: useTaskHierarchy,
	});
};

export const handleChangePublicCreateTaskPageStatusColumn = (projectId, column) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		publicCreateTaskPageStatusColumnId: column,
	});
};

export const changeUseBaseline = (projectId, useBaseline) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		useBaseline: !useBaseline,
	});
};

export const changeSynchBaselineAndScopingDates = (projectId, synchBaselineAndScopingDates, phases) => {
	const project = {id: projectId};
	const synchDates = !synchBaselineAndScopingDates;

	// If user wants to enable synching
	if (synchDates) {
		const hasPhaseWithDifferentDates = phases.some(
			phase =>
				phase.startDay !== phase.baselineStartDay ||
				phase.startMonth !== phase.baselineStartMonth ||
				phase.startYear !== phase.baselineStartYear ||
				phase.deadlineDay !== phase.baselineDeadlineDay ||
				phase.deadlineMonth !== phase.baselineDeadlineMonth ||
				phase.deadlineYear !== phase.baselineDeadlineYear
		);
		if (hasPhaseWithDifferentDates) {
			showModal({
				type: MODAL_TYPE.RELINK_BASELINE_SCOPING_DATES,
				project: project,
				synchDates: synchDates,
			});
		} else {
			Util.CommitMutation(UpdateProjectMutation, {
				project: project,
				synchBaselineAndScopingDates: !synchBaselineAndScopingDates,
			});
		}
	} else {
		Util.CommitMutation(UpdateProjectMutation, {
			project: project,
			synchBaselineAndScopingDates: !synchBaselineAndScopingDates,
		});
	}
};

export const changeUseFixedPriceForBaselineRevenue = (projectId, useFixedPriceForBaselineRevenue) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		useFixedPriceForBaselineRevenue: useFixedPriceForBaselineRevenue,
	});
};

export const changeTaskCostCalculationType = (projectId, taskCostCalculationType) => {
	const project = {id: projectId};

	Util.localStorageDeleteFincancialMap();
	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		taskCostCalculationType: taskCostCalculationType,
	});
};

export const changeUnassignedTaskHourlyCost = (projectId, unassignedTaskHourlyCost) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		unassignedTaskHourlyCost: unassignedTaskHourlyCost,
	});
};
export const changeHoursPrStoryPoint = (projectId, minutes) => {
	const project = {id: projectId};

	Util.CommitMutation(UpdateProjectMutation, {
		project: project,
		minutesPerEstimationPoint: minutes,
	});
};

//Project Group mutations
export const changeProjectGroupName = (projectGroupId, projectGroupName) => {
	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectGroupId,
		name: projectGroupName,
	});
};

export const changeProjectGroupColor = (projectGroupId, color) => {
	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectGroupId,
		color: color,
	});
};
export const changeProjectGroupUseSprint = (projectGroupId, useSprint) => {
	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectGroupId,
		sprintTimeBox: !useSprint,
	});
};
export const changeProjectGroupSprintLength = (projectGroupId, sprintLength) => {
	if (sprintLength < 1) return;

	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectGroupId,
		sprintLengthInDays: sprintLength,
	});
};

export const changeProjectGroupEstimationUnit = (projectGroupId, unit) => {
	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectGroupId,
		estimationUnit: unit,
	});
};
export const changeProjectGoupHoursPrStoryPoint = (projectId, minutes) => {
	Util.CommitMutation(UpdateProjectGroupMutation, {
		id: projectId,
		minutesPerEstimationPoint: minutes,
	});
};

export const changeUseDeliverables = (projectId, useDeliverables) => {
	Util.CommitMutation(UpdateProjectMutation, {
		id: projectId,
		useDeliverables: !useDeliverables,
	});
};
