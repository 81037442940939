import React from 'react';
import ClipboardButton from 'react-clipboard.js';
import styled from 'styled-components';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import CreateJiraVerificationKeyMutation from '../../../../mutations/create_jira_verification_key_mutation';
import DeactivateJiraCloudMutation from '../../../../mutations/deactivate_jira_cloud_mutation';
import * as tracking from '../../../../tracking';
import Util from '../../../../forecast-app/shared/util/util';
import {closeModal, MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import InputField from '../../../../components/new-ui/input_field';
import RadioButton from '../../../../components/new-ui/radio_button';
import jiraUtil from '../../../../components/jira/jira_util';
import UploadingOverlay from '../../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {CSS_CONSTANTS} from '../../../../css_variables';
import OkSign from '../../../../images/v2/components/multi_select_checked_green.svg';
import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import CheckBox from '../../../../components/new-ui/check_box';
import {EpicSelectPopup} from '../../../../components/settings-appcatalog/epic_select_popup';
import {TimeApprovalIncompatibleContent} from '../TimeApprovalIncompatibleContent';
import {hasFeatureFlag, usingTimeApproval} from '../../../../forecast-app/shared/util/FeatureUtil';
import InformationIcon from '../../../../images/information-icon';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import DirectApi from '../../../../directApi';
import VerifyTempoTokenMutation from '../../../../mutations/VerifyTempoTokenMutation';
import {trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';
import JiraTempoOauthTokenSection from './jira_tempo_oauth_token_section';
import {DeprecatedProjectIndicatorNoFragment} from '../../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';
import Switch from '../../../../forecast-app/shared/components/switch/switch';

class appJira extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activated: false,
			synchronizing: false,
			syncedProjectsExpandedMap: new Map(),
			selectedTab: 'projects',
			selectedEpics: [],
			jiraEpics: null,
			tempoToken: null,
			verifyingTempoToken: false,
			tempoTokenValid: false,
			showTokenValidMarkingVerify: false,
			showTokenValidMarkingSubmit: false,
			submittingTempoToken: false,
			submittedTempoTokenValid: false,
			availableJiraEpics: null,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Jira');
	}

	componentWillMount() {
		if (!this.props.viewer.company.jiraVerificationKey) {
			this.activate();
		}
	}

	componentDidMount() {
		document.title = 'Jira - Apps & Integrations - Forecast';

		this.fetchEpics();
		this.fetchAvailableEpics();

		// Segment
		tracking.trackPage('settings-app-catalog-jira');

		if (this.props.showVerifiedPrompt) {
			const VerifiedModalStyles = styled.div`
				.verified-modal-title {
					font-size: 20px;
					font-weight: 500;
					color: ${CSS_CONSTANTS.v2_text_gray};
					margin-bottom: 25px;
					display: flex;
					.ok-sign {
						height: 30px;
						width: 30px;
						background: url(${OkSign});
						background-size: 30px;
						background-position: center;
						background-repeat: no-repeat;
						margin-right: 16px;
					}
				}
				.verified-modal-info {
					font-size: 13px;
					color: #535353;
					margin-bottom: 16px;
					&.bold {
						font-weight: 700;
					}
				}
			`;
			const {formatMessage} = this.props.intl;
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<VerifiedModalStyles>
						<div>
							<div className="verified-modal-title">
								<div className="ok-sign" />
								{formatMessage({id: 'jira_app.verified_confirmation.title'})}
							</div>
							<div className="verified-modal-info bold">
								{formatMessage({id: 'jira_app.verified_confirmation.info_1'})}
							</div>
							<div className="verified-modal-info">
								{formatMessage({id: 'jira_app.verified_confirmation.info_2'})}
							</div>
						</div>
					</VerifiedModalStyles>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: 'Close',
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: 'Sync jira Project',
						callback: () => {
							closeModal();
							this.syncJiraProject();
						},
						preventDefaultClose: true,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
					},
				],
			});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	fetchEpics() {
		if (!this.props.viewer.company.jiraInstallationLinked) return;
		DirectApi.Fetch(`jira/epics`).then(json => {
			if (json instanceof Array) {
				this.setState({
					jiraEpics: json.map(epic => ({
						id: epic.id,
						key: epic.key,
						summary: epic.fields.summary,
					})),
				});
			}
		});
	}

	fetchAvailableEpics() {
		if (!this.props.viewer.company.jiraInstallationLinked) return;
		DirectApi.Fetch(`jira/available_epics`).then(json => {
			if (json instanceof Array) {
				this.setState({
					availableJiraEpics: json.map(epic => ({
						id: epic.id,
						key: epic.key,
						summary: epic.fields.summary,
					})),
				});
			}
		});
	}

	getEpicString(epicIds) {
		if (!epicIds) return '';

		if (!this.state.jiraEpics) return 'Loading...';

		return epicIds
			.map(epicId => {
				const epics = this.state.jiraEpics.find(e => e.id === epicId);
				if (epics) return epics.key;
				return 'Not found';
			})
			.reduce((acc, cur, idx, src) => {
				return acc + cur + (idx + 1 < src.length ? ', ' : '');
			}, '');
	}

	activate() {
		if (!this.props.viewer.company.jiraVerificationKey) {
			Util.CommitMutation(CreateJiraVerificationKeyMutation);
		}

		this.setState({activated: true});
	}

	deactivate() {
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={'settings.app_catalog.jira_cloud.deactivate'} />
					<div className="warning-part-2">
						{this.props.intl.formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}
					</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: this.props.intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: this.props.intl.formatMessage({id: 'common.deactivate'}),
					callback: () => {
						Util.CommitMutation(DeactivateJiraCloudMutation, {});
						this.setState({activated: false});
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	/*
	 * new jira cloud onboarding start
	 */
	async fetchJiraProjectCountDetails(jiraProjectId) {
		const issuesCountObject = await jiraUtil.fetchIssuesCountForJiraProject(jiraProjectId, 'cloud');
		const projectCounts = this.state.projectCounts !== undefined ? this.state.projectCounts : new Map();
		const count = {issuesCount: issuesCountObject.tasks, subtasksCount: issuesCountObject.subtasks};
		projectCounts.set(jiraProjectId, count);
		this.setState({projectCounts});
	}

	toggleSynedProjectExpand(project) {
		const {syncedProjectsExpandedMap} = this.state;
		if (syncedProjectsExpandedMap.has(project.id)) {
			syncedProjectsExpandedMap.set(project.id, !syncedProjectsExpandedMap.get(project.id));
		} else {
			syncedProjectsExpandedMap.set(project.id, true);
			this.fetchJiraProjectCountDetails(project.jiraCloudProject.id);
		}
		this.setState({syncedProjectsExpandedMap});
	}

	changeJiraPrefixType(jiraPrefixType) {
		jiraUtil.changeJiraPrefixType(jiraPrefixType, this.props.viewer.company.id, false);
	}

	syncJiraProject(project) {
		showModal({
			type: MODAL_TYPE.SYNC_JIRA_PROJECT,
			jiraProject: project,
			forecastProject: null,
			syncType: 'project',
			jiraType: 'cloud',
		});

		this.setState({selectedEpics: []});
	}

	syncJiraEpic(epics) {
		showModal({
			type: MODAL_TYPE.SYNC_JIRA_PROJECT,
			jiraEpics: epics,
			syncType: 'epics',
			jiraType: 'cloud',
		});

		this.setState({selectedEpics: []});
	}

	unsyncJiraProject(project) {
		jiraUtil.unlinkProject(project);
	}

	verifyTempoToken() {
		this.setState({showTokenValidMarkingSubmit: false});

		const onSuccess = result => {
			if (result && result.verifyTempoToken) {
				if (result.verifyTempoToken.statusCode === 200) {
					this.setState({tempoTokenValid: true, verifyingTempoToken: false});
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage(
							{
								id: 'app_jira.integrate_page.tempo.token_check.valid',
							},
							{worklogCount: result.verifyTempoToken.worklogCount}
						),
					});
					return;
				} else if (result.verifyTempoToken.statusCode === 401) {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({
							id: 'app_jira.integrate_page.tempo.token_check.invalid',
						}),
					});
				} else if (result.verifyTempoToken.statusCode === 403) {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({
							id: 'app_jira.integrate_page.tempo.token_check.missing_permissions',
						}),
					});
				} else {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({
							id: 'app_jira.integrate_page.tempo.token_check.error',
						}),
					});
				}
			}
			this.setState({tempoTokenValid: false, verifyingTempoToken: false});
		};

		const onError = () => {
			this.setState({tempoTokenValid: false, verifyingTempoToken: false});
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({
					id: 'app_jira.integrate_page.tempo.token_check.error',
				}),
			});
		};
		Util.CommitMutation(VerifyTempoTokenMutation, null, onSuccess, true, null, onError);

		this.setState({verifyingTempoToken: true, showTokenValidMarkingVerify: true});
	}

	getPageHTML() {
		return (
			<div className="section-content settings-app settings-app-page settings-app-jira">
				{this.state.selectedTab === 'epics' && this.state.selectedEpics.length > 0 && (
					<EpicSelectPopup
						epicIds={this.state.selectedEpics}
						options={[
							{
								id: 'sync_epics',
								label: this.props.intl.formatMessage({id: 'integrations.jira.sync_epics'}),
								callback: this.syncJiraEpic.bind(this, this.state.selectedEpics),
							},
						]}
						onClose={() => this.setState({selectedEpics: []})}
					/>
				)}
				<div className="header">
					<button className="back-button" onClick={this.props.history.goBack}>
						Integrations
					</button>
				</div>
				<CustomScrollDiv>
					{this.props.viewer.company.jiraInstallationLinked
						? this.getIntegrationLiveHTML()
						: this.getSetupIntegrationHTML()}
				</CustomScrollDiv>
			</div>
		);
	}

	getProjectsTable(isSyncedProjects, projects) {
		const {formatMessage} = this.props.intl;
		const {syncedProjectsExpandedMap, projectCounts} = this.state;
		return (
			<table className="jira-projects-sync-table">
				<thead>
					<tr>
						{isSyncedProjects ? (
							<th className="expand-control-spacing">
								<div>{formatMessage({id: 'common.forecast_project'})}</div>
							</th>
						) : null}
						<th>
							<div>{formatMessage({id: 'common.jira_project'})}</div>
						</th>
						<th className="sync-controls-cell"></th>
					</tr>
				</thead>
				<tbody>
					{projects.map((project, index) => (
						<tr key={index}>
							<td>
								{isSyncedProjects ? (
									<div className="project-row">
										<button
											className={`expand-control ${
												syncedProjectsExpandedMap.has(project.id) &&
												syncedProjectsExpandedMap.get(project.id)
													? 'expanded'
													: ''
											}`}
											onClick={this.toggleSynedProjectExpand.bind(this, project)}
										/>
										<div className="forecast-project-wrapper">
											<DeprecatedProjectIndicatorNoFragment project={project} showProjectName />
										</div>
									</div>
								) : (
									project.name
								)}
								{isSyncedProjects &&
								syncedProjectsExpandedMap.has(project.id) &&
								syncedProjectsExpandedMap.get(project.id) ? (
									projectCounts && projectCounts.has(project.jiraCloudProject.id) ? (
										<div className="project-info-count-wrapper">
											<span>
												<span className="value">
													{projectCounts.get(project.jiraCloudProject.id).issuesCount}
												</span>{' '}
												<FormattedMessage id="common.tasks" />
											</span>
											{projectCounts.get(project.jiraCloudProject.id).subtasksCount ? (
												<span>
													<span className="value">
														{projectCounts.get(project.jiraCloudProject.id).subtasksCount}
													</span>{' '}
													<FormattedMessage id="common.sub_tasks" />
												</span>
											) : null}
										</div>
									) : (
										<UploadingOverlay />
									)
								) : null}
							</td>
							{isSyncedProjects ? <td>{project.jiraCloudProject.name}</td> : null}
							<td className="sync-controls-cell">
								{isSyncedProjects ? (
									<div>
										<button onClick={this.unsyncJiraProject.bind(this, project)} className="unsync-button">
											{formatMessage({id: 'app_jira.live_page.stop_sync'})}
										</button>
									</div>
								) : (
									<button onClick={this.syncJiraProject.bind(this, project)} className="sync-button">
										{formatMessage({id: 'app_jira.live_page.sync'})}
									</button>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	getEpicsTable(isSyncedProjects, projects, epics) {
		if (epics == null) epics = [];

		const {formatMessage} = this.props.intl;
		return (
			<table className="jira-projects-sync-table">
				<thead>
					<tr>
						{isSyncedProjects ? (
							<>
								<th>
									<div>{formatMessage({id: 'common.forecast_project'})}</div>
								</th>
								<th>
									<div>{formatMessage({id: 'integrations.jira.epics'})}</div>
								</th>
							</>
						) : (
							<>
								<th className="check-column-cell"></th>
								<th className="pre-spacing">
									<div>{formatMessage({id: 'integrations.jira.epic'})}</div>
								</th>
							</>
						)}
						<th className="sync-controls-cell"></th>
					</tr>
				</thead>
				<tbody>
					{isSyncedProjects
						? projects.map((project, index) => (
								<tr key={index}>
									<td>
										<div className="project-row">
											<div className="forecast-project-wrapper">
												<div
													className="forecast-project-indicator"
													style={{
														backgroundColor: project.projectColor,
														color: Util.getTextColorV2(project.projectColor),
													}}
												>
													{project.customProjectId
														? project.customProjectId
														: `P${project.companyProjectId}`}
												</div>
												{project.name}
											</div>
										</div>
									</td>
									<td>{this.getEpicString(project?.jiraCloudEpicIds)}</td>
									<td className="sync-controls-cell">
										<button onClick={this.unsyncJiraProject.bind(this, project)} className="unsync-button">
											{formatMessage({id: 'app_jira.live_page.stop_sync'})}
										</button>
									</td>
								</tr>
						  ))
						: epics.map((epic, index) => (
								<tr key={index} className="epics-table">
									<td>
										<CheckBox
											small={true}
											isFocusable={true}
											isChecked={this.state.selectedEpics.includes(epic.id)}
											onClick={this.selectEpic.bind(this, epic.id)}
										/>
									</td>
									<td className="pre-spacing">{epic.key + ' ' + epic.summary}</td>
									<td className="sync-controls-cell">
										<button onClick={this.syncJiraEpic.bind(this, [epic.id])} className="sync-button">
											{formatMessage({id: 'app_jira.live_page.sync_epic'})}
										</button>
									</td>
								</tr>
						  ))}
				</tbody>
			</table>
		);
	}

	selectEpic(epicId) {
		let selectedEpics = [...this.state.selectedEpics];

		if (selectedEpics.includes(epicId)) {
			selectedEpics = selectedEpics.filter(val => val !== epicId);
		} else {
			selectedEpics.push(epicId);
		}
		this.setState({selectedEpics});
	}

	changeStartDateField() {
		showModal({
			type: MODAL_TYPE.JIRA_START_DATE,
		});
	}

	getIntegrationLiveHTML() {
		const {formatMessage} = this.props.intl;

		const projectsSynchedToJiraProjects = this.props.viewer.projects.edges
			.filter(project => project.node.jiraCloudProject)
			.map(project => project.node);
		const projectsSynchedToJiraEpics = this.props.viewer.projects.edges
			.filter(project => project.node.jiraCloudEpicIds)
			.map(project => project.node);

		const {unSynchedJiraProjects} = this.props.viewer.company;
		const jiraStartDateField = this.props.viewer?.company?.integrations?.jiraCloud?.startDateField;

		return (
			<div className="content">
				{this.getContentHeader(formatMessage({id: 'app_jira.live_page.title'}))}
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">{formatMessage({id: 'settings.title'})}</span>
					<div className="settings-app-jira-section-body">
						{hasFeatureFlag('jira_to_forecast_one_way_sync') && (
							<div className="current-settings">
								<div className="settings-title-container">
									<div className="current-settings-title">sync settings</div>
								</div>
								<div style={{display: 'flex', gap: '4px'}}>
									<Switch
										checked={
											this.props.viewer.company.integrations.jiraCloud.syncSettings
												.isJiraToForecastOneWaySync
										}
										onChange={jiraUtil.toggleJiraCloudToForecastOneWaySync}
										sliderWidth={38}
										sliderHeight={20}
									/>
									<div className="info-text">
										{formatMessage({id: 'integration.jira.one_way_sync_description'})}
									</div>
								</div>
							</div>
						)}
						<div className="current-settings">
							<div className="settings-title-container">
								<div className="current-settings-title">
									{formatMessage({id: 'settings.app_catalog.jira.prefix.title'})}
								</div>
								<ForecastTooltip
									content={formatMessage({id: 'app_jira.live_page.prefix_section.info'})}
									delay={0}
								>
									<InformationIcon />
								</ForecastTooltip>
							</div>

							<RadioButton
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_1'})}
								onSelected={this.changeJiraPrefixType.bind(this)}
								value="NONE"
								stateValue={this.props.viewer.company.jiraPrefixType}
								locked={this.props.viewer.company.jiraPrefixLoading}
							/>
							<RadioButton
								onSelected={this.changeJiraPrefixType.bind(this)}
								value="SIMPLE"
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_2'})}
								stateValue={this.props.viewer.company.jiraPrefixType}
								locked={this.props.viewer.company.jiraPrefixLoading}
							/>
							<RadioButton
								text={formatMessage({id: 'app_jira.live_page.prefix_section.option_3'})}
								value="FULL"
								onSelected={this.changeJiraPrefixType.bind(this)}
								stateValue={this.props.viewer.company.jiraPrefixType}
								locked={this.props.viewer.company.jiraPrefixLoading}
							/>
						</div>
						<div className="current-settings">
							<div className="settings-title-container">
								<div className="current-settings-title">
									{formatMessage({id: 'settings.app_catalog.jira.startdate.title'})}
								</div>
								<ForecastTooltip
									content={formatMessage({id: 'integrations.jira.start_date.description'})}
									delay={0}
								>
									<InformationIcon />
								</ForecastTooltip>
							</div>
							<div className="info-text">
								<FormattedMessage
									id="settings.app_catalog.jira.selected_start_date_field"
									values={{
										b: chunks => <b>{chunks}</b>,
										field: `${jiraStartDateField?.name || 'none'} ${
											jiraStartDateField?.id ? `(${jiraStartDateField.id})` : ''
										}`,
									}}
								/>
							</div>
							<Button
								className="settings-button"
								buttonStyle={BUTTON_STYLE.OUTLINE}
								colorTheme={BUTTON_COLOR.WHITE}
								text={formatMessage({id: 'common.change'})}
								onClick={this.changeStartDateField.bind(this)}
							/>
						</div>
					</div>
				</div>
				<JiraTempoOauthTokenSection
					company={this.props.viewer.company}
					tempoOauthTokenApplied={this.props.viewer.company.tempoOauthTokenApplied}
					tempoApiTokenApplied={this.props.viewer.company.tempoTokenApplied}
					verifyTempoApiToken={this.verifyTempoToken.bind(this)}
					verifyingTempoApiToken={this.state.verifyingTempoToken}
					submittedTempoTokenValid={this.state.submittedTempoTokenValid}
					tempoApiTokenValid={this.state.tempoTokenValid}
				/>
				<div className="tabs settings-app-jira-section live">
					<div className="tablist" role="tablist" aria-label="Jira Sync Types">
						<button
							className={this.state.selectedTab === 'projects' ? 'active' : ''}
							role="tab"
							onClick={() => {
								this.setState({selectedTab: 'projects'});
							}}
							aria-selected={this.state.selectedTab === 'projects'}
							aria-controls="projects-tab"
							id="projects"
						>
							<FormattedMessage id="common.projects" />
						</button>
						<button
							className={this.state.selectedTab === 'epics' ? 'active' : ''}
							role="tab"
							onClick={() => {
								this.setState({selectedTab: 'epics'});
							}}
							aria-selected={this.state.selectedTab === 'epics'}
							aria-controls="epics-tab"
							id="epics"
							tabIndex="-1"
						>
							<FormattedMessage id="integrations.jira.epics" />
						</button>
					</div>
					<div
						role="tabpanel"
						id="projects-tab"
						aria-labelledby="projects"
						hidden={this.state.selectedTab !== 'projects'}
					>
						<div className="settings-app-jira-section live inner">
							<span className="settings-app-jira-section-title standard">
								{formatMessage({id: 'app_jira.live_page.unsynced_projects_section.title'})}
							</span>
							<div className="settings-app-jira-section-body">
								{this.getProjectsTable(false, unSynchedJiraProjects)}
							</div>
						</div>
						<div className="settings-app-jira-section live inner">
							<span className="settings-app-jira-section-title standard">
								{formatMessage({id: 'app_jira.live_page.synced_projects_section.title'})}
							</span>
							<div className="settings-app-jira-section-body">
								{this.getProjectsTable(true, projectsSynchedToJiraProjects)}
							</div>
						</div>
					</div>
					<div role="tabpanel" id="epics-tab" aria-labelledby="epics" hidden={this.state.selectedTab !== 'epics'}>
						<div className="settings-app-jira-section live inner">
							<span className="settings-app-jira-section-title standard">
								{formatMessage({id: 'app_jira.live_page.unsynced_epics_section.title'})}
							</span>
							<div className="settings-app-jira-section-body">
								{this.getEpicsTable(false, null, this.state.availableJiraEpics ?? [])}
							</div>
						</div>
						<div className="settings-app-jira-section live inner">
							<span className="settings-app-jira-section-title standard">
								{formatMessage({id: 'app_jira.live_page.synced_epics_section.title'})}
							</span>
							<div className="settings-app-jira-section-body">
								{this.getEpicsTable(true, projectsSynchedToJiraEpics)}
							</div>
						</div>
					</div>
				</div>
				<div className="settings-app-jira-section live">
					<span className="settings-app-jira-section-title standard">
						{formatMessage({id: 'app_jira.live_page.disconnect_section.title'})}
					</span>
					<span className="info-text">{formatMessage({id: 'app_jira.live_page.disconnect_section.info'})}</span>
					<div className="buttons">
						<Button
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.RED}
							text={formatMessage({id: 'app_jira.live_page.disconnect_section.disconnect_button'})}
							onClick={this.deactivate.bind(this)}
						/>
					</div>
				</div>
			</div>
		);
	}

	getContentHeader(title) {
		return (
			<div className="settings-app-jira-section center">
				<span className="settings-app-jira-section-title large">{title}</span>
				<div className="logos-wrapper">
					<div className="jira-cloud-logo" />
					<div className="arrows-icon" />
					<div className="forecast-logo" />
				</div>
			</div>
		);
	}

	getSetupIntegrationHTML() {
		const {formatMessage} = this.props.intl;
		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);
		return (
			<div className="content">
				{useTimeApproval && !hasFeatureFlag('time_approval_integrations') ? (
					<TimeApprovalIncompatibleContent integrationName={'JIRA'} />
				) : (
					<>
						{this.getContentHeader(formatMessage({id: 'app_jira.integrate_page.title'}))}
						<div className="settings-app-jira-section setup">
							<span className="settings-app-jira-section-title small">
								1. {formatMessage({id: 'app_jira.integrate_page.step_one.title'})}
							</span>
							<span className="info-text">{formatMessage({id: 'app_jira.integrate_page.step_one.info'})}</span>
							<div className="jira-cloud-verification-key-controls">
								<InputField
									hideLabel
									value={
										this.props.viewer.company.jiraVerificationKey
											? this.props.viewer.company.jiraVerificationKey
											: ''
									}
								/>
								<ClipboardButton
									className="copy-jira-verification-key-button"
									title={'copy key'}
									data-clipboard-text={this.props.viewer.company.jiraVerificationKey}
									onSuccess={() => {
										createToast({
											duration: 5000,
											message: formatMessage({id: 'common.copied_to_clipboard'}),
										});
									}}
								>
									{formatMessage({id: 'common.copy_key'})}
								</ClipboardButton>
							</div>
						</div>
						<div className="settings-app-jira-section setup">
							<span className="settings-app-jira-section-title small">
								2. {formatMessage({id: 'app_jira.integrate_page.step_two.title'})}
							</span>
							<span className="info-text">
								{formatMessage({id: 'app_jira.integrate_page.step_two.info_1'})}{' '}
								<a
									className="info-text link"
									target="_blank"
									rel="noopener noreferrer"
									href="https://marketplace.atlassian.com/apps/1217423/forecast-add-on-for-jira?hosting=cloud&tab=overview"
								>
									{formatMessage({id: 'common.atlassian_marketplace'})}
								</a>{' '}
								{formatMessage({id: 'app_jira.integrate_page.step_two.info_2'})} "
								<span className="info-text bold">{formatMessage({id: 'common.get_it_now'})}</span>"{' '}
								{formatMessage({id: 'app_jira.integrate_page.step_two.info_3'})} "
								<span className="info-text bold">{formatMessage({id: 'common.install_app'})}</span>".
							</span>
							<div className="images-wrapper">
								<img
									className="jira-onboarding-image"
									alt="jira-onboarding"
									src={require('../../../../images/integrations/jira/JiraCloudCapture1.JPG')}
								/>
								<img
									className="jira-onboarding-image"
									alt="jira-onboarding"
									src={require('../../../../images/integrations/jira/JiraCloudCapture2.JPG')}
								/>
							</div>
						</div>
						<div className="settings-app-jira-section setup">
							<span className="settings-app-jira-section-title small">
								3. {formatMessage({id: 'app_jira.integrate_page.step_three.title'})}
							</span>
							<span className="info-text">
								{formatMessage({id: 'app_jira.integrate_page.step_three.info_1'})}
								{' ' + formatMessage({id: 'common.get_started'}) + ' '}
								{formatMessage({id: 'app_jira.integrate_page.step_three.info_2'})} "
								<span className="info-text bold">{formatMessage({id: 'common.verify'})}</span>".
							</span>
							<img
								className="jira-onboarding-image"
								alt="jira-onboarding"
								src={require('../../../../images/integrations/jira/JiraForecastApp.JPG')}
							/>
						</div>
						<div className="settings-app-jira-section setup last">
							<span className="settings-app-jira-section-title small">
								4. {formatMessage({id: 'app_jira.integrate_page.step_four.title'})}
							</span>
							<span className="info-text">{formatMessage({id: 'app_jira.integrate_page.step_four.info'})}</span>
						</div>
					</>
				)}
			</div>
		);
	}

	/*
	 * new jira cloud onboarding end
	 */

	render() {
		return this.getPageHTML();
	}
}

const appJiraQuery = graphql`
	query appJira_Query {
		viewer {
			actualPersonId
			component(name: "app_jira")
			...appJira_viewer
		}
	}
`;

export {appJiraQuery};

export default createFragmentContainer(injectIntl(withRouter(appJira)), {
	viewer: graphql`
		fragment appJira_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			company {
				...jiraTempoOauthTokenSection_company
				id
				useTimeApproval
				modules {
					moduleType
				}
				jiraVerificationKey
				jiraInstallationLinked
				jiraPrefixType
				jiraPrefixLoading
				tempoTokenApplied
				unSynchedJiraProjects {
					id
					name
					key
					style
				}
				integrations {
					jiraCloud {
						startDateField {
							id
							name
						}
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
				}
			}
			projects(first: 10000) @connection(key: "Viewer_projects") {
				edges {
					node {
						id
						projectColor
						companyProjectId
						customProjectId
						name
						isJiraProject
						jiraCloudProject {
							style
							name
							id
						}
						jiraCloudEpicIds
					}
				}
			}
		}
	`,
});
